import React from 'react';
import mapboxgl from 'mapbox-gl';

import './MapboxMap.css';

class MapboxMap extends React.Component {
  constructor(props) {
    super(props);


    this.map = undefined;
    this.mapContainerRef = React.createRef();
  }
  componentDidMount() {
    const options = Object.assign({}, this.props, {
      container: this.mapContainerRef.current,
    });
    this.map = new mapboxgl.Map(options);
  }

  componentDidUpdate(prevProps) {
    if (this.props.center && prevProps.center !== this.props.center) {
      this.map.setCenter(this.props.center);
    }
  }

  render() {
    return (
      <div className="map" ref={this.mapContainerRef}></div>
    );
  }
}

export default MapboxMap;
