import React from 'react';
import mapboxgl from 'mapbox-gl';

import { AppConfig } from './AppConfig';
import MapLocations from './MapLocations';
import InfoPanel from './components/InfoPanel';
import MapboxMap from './components/MapboxMap';

import './App.css';

mapboxgl.accessToken = AppConfig.mapbox.token;

class App extends React.Component {

  constructor(props) {
    super(props);

    this.loadRandomMapLocation = this.loadRandomMapLocation.bind(this);
    this.locations = new MapLocations();

    this.state = {
      mapCenter: this.locations.random(),
    };
  }

  loadRandomMapLocation() {
    this.setState({mapCenter: this.locations.random()});
  }

  render() {
    return (
      <div>
        <MapboxMap style={AppConfig.mapbox.style}
                   zoom={AppConfig.mapbox.defaultZoom}
                   center={this.state.mapCenter}
                   interactive={false}></MapboxMap>
        <InfoPanel mapRefreshClicked={this.loadRandomMapLocation}></InfoPanel>
      </div>
    );
  }
}

export default App;
