
class MapLocations {
  lastIndex = -1;
  locations = [
      [-75.203493, 40.041435],    // Wissahickon Park, PHL, PA
      [-68.921866, 45.910925],    // Mt. Katahdin
      [-120.058599, 38.697867],   // Kirkwood
      [-122.590169, 37.902166],   // Muir Woods/Mt. Tam
      [-75.529847, 35.258965],   // Cape Hatteras
  ];

  list() {
    return this.locations;
  }

  random() {
    if (!(this.locations && this.locations.length)) {
      return null;
    }
    const numLocations = this.locations.length;
    let index = this.lastIndex;
    while (index === this.lastIndex) {
        index = Math.floor(Math.random() * (numLocations));
    }
    this.lastIndex = index;
    return this.locations[index];
  }
}

export default MapLocations;
