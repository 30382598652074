import React from 'react';

import './InfoPanel.css';

function InfoPanel(props) {
  return (
    <div className="container">
      <div className="panel panel-jumbotron">
        <div className="panel-body">
          <h1 className="text-center">Andrew Fink-Miller</h1>
          <p className="lead text-center">Senior Software Engineer / Tech Lead &#64; <a href="https://www.azavea.com">Azavea</a></p>
          <p className="lead text-center">Philadelphia, PA</p>
        </div>
        <div className="panel-footer">
          <div className="row">
            <div className="col-xs-4 col-md-4">
              <button id="refresh-center"
                      className="btn btn-link pointer"
                      onClick={props.mapRefreshClicked}>
                <i className="fas fa-sync fa-2x"></i>
              </button>
            </div>
            <div className="col-xs-8 col-md-8">
              <a href="https://www.linkedin.com/in/cloudniner" className="btn btn-link pull-right">
                <i className="fab fa-linkedin fa-3x social-link"></i>
              </a>
              <a href="https://github.com/cloudniner" className="btn btn-link pull-right">
                <i className="fab fa-github-square fa-3x social-link"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoPanel;
